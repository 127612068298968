<template>
  <div class="personal-center-container">
    <el-row :gutter="20">
      <el-col :lg="8" :md="12" :sm="24" :xl="8" :xs="24">
        <vab-card shadow="hover">
          <div class="personal-center-user-info">
            <el-avatar
              :size="100"
              :src="userInfo.avatar"
              @click="openDialog"
              v-if="userInfo.avatar"
            />
            <el-avatar
              :size="100"
              src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/staticHeader.gif"
              @click="openDialog"
              v-else
            />
            <div class="personal-center-user-info-full-name">
              {{ userInfo.username }}
            </div>
            <ul class="personal-center-user-info-list">
              <li>
                <vab-icon icon="user-3-line" />
                {{ userInfo.nickName }}
              </li>
              <li>
                <vab-icon icon="magic-line" />
                {{ userInfo.phone }}
              </li>
              <li>
                <vab-icon icon="women-line" />
                {{
                  userInfo.sex == 0 ? '其他' : userInfo.sex == 1 ? '男' : '女'
                }}
              </li>
              <li>
                <vab-icon icon="community-line" />
                {{ userInfo.email }}
              </li>
            </ul>
          </div>
        </vab-card>
      </el-col>
      <el-col :lg="16" :md="12" :sm="24" :xl="16" :xs="24">
        <vab-card shadow="hover">
          <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="first">
              <el-col :lg="12" :md="16" :sm="24" :xl="12" :xs="24">
                <el-form label-width="80px" :model="form">
                  <el-form-item label="真实姓名">
                    <el-input v-model="form.realName" />
                  </el-form-item>
                  <el-form-item label="昵称">
                    <el-input v-model="form.nickName" />
                  </el-form-item>
                  <el-form-item label="手机号">
                    <el-input v-model="form.phone" />
                  </el-form-item>
                  <el-form-item label="性别">
                    <el-select v-model="form.sex" style="width: 100%">
                      <el-option label="其他" :value="0" />
                      <el-option label="男" :value="1" />
                      <el-option label="女" :value="2" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="邮箱">
                    <el-input v-model="form.email" />
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="onSubmit">保存</el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="修改密码" name="third">
              <el-col :lg="12" :md="16" :sm="24" :xl="12" :xs="24">
                <el-form
                  label-width="80px"
                  :model="resetPassForm"
                  ref="passwordFormRef"
                >
                  <el-form-item label="旧密码">
                    <el-input
                      v-model="resetPassForm.oldPassword"
                      show-password
                    />
                  </el-form-item>
                  <el-form-item label="新密码">
                    <el-input
                      v-model="resetPassForm.newPassword"
                      show-password
                    />
                  </el-form-item>
                  <el-form-item label="确认密码">
                    <el-input
                      v-model="resetPassForm.secondPass"
                      show-password
                    />
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="changePassword">
                      保存
                    </el-button>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="账号绑定" name="second">
              <div class="personal-center-item">
                <vab-icon icon="wechat-line" style="color: #4daf29" />
                <div class="personal-center-item-content">
                  <div>绑定微信</div>
                  <div class="personal-center-item-content-second">
                    {{
                      form.isBindWechat
                        ? '当前已绑定微信账号'
                        : '当前未绑定微信账号'
                    }}
                  </div>
                </div>
                <el-link type="primary" @click="bindWeChart">
                  {{ form.isBindWechat ? '去解绑' : '去绑定' }}
                </el-link>
              </div>
              <el-divider />
            </el-tab-pane>
          </el-tabs>
        </vab-card>
      </el-col>
    </el-row>
    <vab-cropper
      ref="vabCropperRef"
      @upDateUserInfoFunc="upDateUserInfoFunc"
      :url="userInfo.avatar"
    />
    <el-dialog
      title="绑定微信"
      v-model="dialogVisible"
      width="30%"
      :before-close="closeDialog"
    >
      <el-image
        :src="qrCodeByWeChart"
        :preview-src-list="[qrCodeByWeChart]"
      ></el-image>
    </el-dialog>
  </div>
</template>

<script>
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    reactive,
    onMounted,
    toRefs,
  } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter, useRoute } from 'vue-router'
  import { toLoginRoute } from '@/utils/routes'
  import VabCropper from '@/extra/VabCropper'
  import {
    getUserDetail,
    upDateUserInfo,
    bindChartStatus,
    unbindWeChart,
    upDateUserPassword,
    generateBindUrl,
  } from '@/api/user'
  export default defineComponent({
    name: 'PersonalCenter',
    components: { VabCropper },
    setup() {
      const store = useStore()

      const { proxy } = getCurrentInstance()

      const _fullName = unescape('\u695a\u829d\u99a8')
      const _description = unescape(
        '\u5bcc\u5728\u672f\u6570\uff0c\u4e0d\u5728\u52b3\u8eab\uff1b\u5229\u5728\u52bf\u5c45\uff0c\u4e0d\u5728\u529b\u8015\u3002'
      )

      const state = reactive({
        vabCropperRef: null,
        passwordFormRef: {},
        resetPassForm: {
          oldPassword: '',
          newPassword: '',
          secondPass: '',
        },
        activeName: 'first',
        bindStatus: null,
        dialogVisible: false,
        qrCodeByWeChart: '',
        userInfo: computed(() => store.getters['user/user']),
        form: {
          realName: '',
          nickName: '',
          sex: '',
          email: '',
        },
      })

      const userInfoFunc = () => store.dispatch('user/getUserInfo')
      const openDialog = () => {
        state['vabCropperRef'].dialogVisible = true
      }
      const onSubmit = async () => {
        const { msg } = await upDateUserInfo(state.form)
        proxy.$baseMessage(msg, 'success')
        userInfoFunc()
      }

      const getUserInfo = async () => {
        const { data } = await getUserDetail()
        state.form = data
      }

      const upDateUserInfoFunc = async (e) => {
        state.userInfo.avatar = e
        state.form.avatar = e
        const { msg } = await upDateUserInfo(state.userInfo)
        userInfoFunc()
        proxy.$baseMessage(msg, 'success')
      }

      onMounted(() => {
        getUserInfo()
        console.log(state.userInfo)
      })

      /**
       * @description: 微信绑定
       * @return {*}
       */

      const bindWeChart = () => {
        if (state.form.isBindWechat) {
          proxy.$baseConfirm('你确定要解绑微信吗？', null, async () => {
            unbindWeChart()
              .then((res) => {
                proxy.$baseMessage('解绑成功', 'success')
                userInfoFunc()
                getUserInfo()
              })
              .catch((err) => {
                proxy.$baseMessage(err.msg, 'error')
              })
          })
        } else {
          generateBindUrl().then((res) => {
            console.log(res)
            state.dialogVisible = true
            state.qrCodeByWeChart = res.data.qrCodeUrl
            state.bindStatus = setInterval(() => {
              bindChartStatus()
                .then((resp) => {
                  const { isBind } = resp.data
                  if (isBind) {
                    clearInterval(state.bindStatus)
                    proxy.$baseMessage('绑定成功', 'success')
                    state.dialogVisible = false
                    getUserInfo()
                  }
                })
                .catch((err) => {
                  clearInterval(state.bindStatus)
                })
            }, 1000)
          })
        }
      }

      const closeDialog = () => {
        clearInterval(state.bindStatus)
        state.dialogVisible = false
      }

      const logout = () => store.dispatch('user/logout')

      const route = useRoute()
      const router = useRouter()
      const changePassword = () => {
        state['passwordFormRef'].validate(async (valid) => {
          if (valid) {
            console.log(
              state.resetPassForm.newPassword,
              state.resetPassForm.secondPass
            )
            if (
              state.resetPassForm.newPassword != state.resetPassForm.secondPass
            ) {
              proxy.$baseMessage(`两次密码不一致，请确认后重试`, 'error')
              return false
            }
            state.resetPassForm.uid = state.form.uid
            const { msg } = await upDateUserPassword(state.resetPassForm)
            proxy.$baseMessage(msg, 'success')
            await logout()
            await router.push(toLoginRoute(route.fullPath))
          } else {
            return false
          }
        })
      }

      return {
        ...toRefs(state),
        openDialog,
        getUserInfo,
        onSubmit,
        upDateUserInfoFunc,
        bindWeChart,
        closeDialog,
        changePassword,
      }
    },
  })
</script>

<style lang="scss" scoped>
  $base: '.personal-center';
  #{$base}-container {
    padding: 0 !important;
    background: $base-color-background !important;

    #{$base}-user-info {
      padding: $base-padding;
      text-align: center;

      :deep() {
        .el-avatar {
          img {
            cursor: pointer;
          }
        }
      }

      &-full-name {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 500;
        color: #262626;
      }

      &-description {
        margin-top: 8px;
      }

      &-follow {
        margin-top: 15px;
      }

      &-list {
        margin-top: 18px;
        line-height: 30px;
        text-align: left;
        list-style: none;

        h5 {
          margin: -20px 0 5px 0;
        }

        :deep() {
          .el-tag {
            margin-right: 10px !important;
          }

          .el-tag + .el-tag {
            margin-left: 0;
          }
        }
      }
    }

    #{$base}-item {
      display: flex;

      i {
        font-size: 40px;
      }

      &-content {
        box-sizing: border-box;
        flex: 1;
        margin-left: $base-margin;

        &-second {
          margin-top: 8px;
        }
      }
    }
  }
</style>
